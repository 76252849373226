<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ dataId !== null ? $t('Edit'):$t('Add') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>

            <!-- key -->
            <b-col cols="12">
              <b-form-group
                :label="$t('API_KEY')"
                label-for="key"
              >
                <validation-provider
                  #default="{ errors }"
                  name="key"
                  rules="required"
                >
                  <b-form-input
                    id="key"
                    v-model="key"
                    :state="errors.length > 0 ? false:null"
                    placeholder="key"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- mapMode (vue-select) -->
            <b-col md="12">
              <b-form-group
                :label="$t('MAP_MODE')"
                label-for="mapMode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="mapMode"
                  rules="required"
                >
                  <v-select
                    id="mapMode"
                    v-model="mapMode"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="mapModeData"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>

          </b-row>

          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ dataId !== null ? $t('Edit'):$t('Add') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import {
//   required, length,
// } from '@validations'

export default {
  components: {
    vSelect,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      key: '',
      mapMode: '',

      required,
    }
  },
  computed: {
    mapModeData() {
      return [
        { value: 'driving', text: this.$t('Driving') },
        { value: 'walking', text: this.$t('Walking') },
      ]
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, key, mapMode,
          } = this.data
          this.dataId = _id
          this.key = key
          this.mapMode = mapMode
          if (mapMode === 'driving') {
            this.mapMode = { value: 'driving', text: this.$t('Driving') }
          } else {
            this.mapMode = { value: 'walking', text: this.$t('Walking') }
          }
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.key = ''
      this.mapMode = ''
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            _id: this.dataId,
            key: this.key,
            mapMode: this.mapMode.value,
          }
          console.log(obj)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                console.log('fetch Success : ', result)
                this.showToast('success', 'bottom-right', result.data.message, 'CheckIcon', 'แก้ไขข้อมูลสำเร็จ')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'แก้ไขข้อมูลไม่สำเร็จ')
              })
          } else {
            delete obj._id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                console.log('fetch Success : ', result.data.message, 'check-circle')
                this.showToast('success', 'bottom-right', result.data.message, 'check-circle', 'เพิ่มข้อมูลสำเร็จ')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.showToast('danger', 'bottom-right', error.data.message, 'XCircleIcon', 'เพิ่มข้อมูลไม่สำเร็จ')
              })
          }
        }
      })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
